.UpcomingExperiences {
  margin-top: 10px;
  clear: both;

  .Title {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }

  .ExperienceCard {
    width: 240px;
    float: left;
    margin-right: 10px;
  }
}
