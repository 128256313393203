.CreateAccountPage, .AddPersonaPage {
  margin-top: 30px;

  button {
    margin-top: 20px;
  }
}

.CreateAccountPage {
  .DobSelect {
    margin-top: 10px;
  }

  .NewsletterOptIn {
    margin-top: 20px;
  }
}

.AddPersonaPage {
  .GenderSelect {
    margin-top: 10px;
  }
}
