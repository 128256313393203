$deleteRed: #B22;

.Gifts {

  .GiftList {
    margin-top: 20px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .Gift {
      position: relative;
      cursor: pointer;
      float: left;
      width: 200px;
      height: 200px;
      img {
        max-width: 200px;
      }
      margin-right: 4px;

      .GiftSelect {
        color: #777;
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 10;
        width: 32px;
        height: 32px;

        &.Selected {
          color: $deleteRed;
        }
      }
    }
  }

  .GiftsDelete {
    margin-top: 10px;
    .GiftsDeleteButton {
      background-color: $deleteRed;
    }
  }
}
