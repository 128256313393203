.FeedPage {
  .Gifts {
    .Gift {
      position: relative;

      img {
        width: 500px;
      }

      button {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 10;
      }
    }
  }
}
