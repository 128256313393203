.StatsControls {
  margin-bottom: 10px;

  .DatePicker {
    .DatePickerInput {
      margin-right: 3px;
    }

    .DayPickerWrapper {
      width: 530px;
      margin-top: 5px;

      .DayPicker {
        .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background-color: #f0f8ff !important;
          color: #4a90e2;
        }
        .DayPicker-Day {
          border-radius: 0 !important;
        }
        .DayPicker-Day--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }
        .DayPicker-Day--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }
      }
    }
  }
}

.ChartWrapper {
  width: 500px;
}

.Filters {
  margin-top: 10px;

  .Filter {
    display: inline-block;
    margin-right: 5px;
    position: relative;

    .FilterLabel {
      // Copy a bunch of stuff from mdc-text-field--outlined
      position: absolute;
      color: rgba(0, 0, 0, 0.6);
      left: 16px;
      right: initial;
      width: auto;
      -webkit-transform: scale(0.75);
      transform: scale(0.75);
      left: 12px;
      top: -6px;
      background-color: white;
      padding: 0 4px 0 4px;
      pointer-events: none;
    }
  }
}

.AllStats {
  display: flex;
  flex-wrap: wrap;
  .StatCard {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;

    .Header {
      //background-color: #ce93d8ff;
    }
    .Body {
      //background-image: linear-gradient(to bottom, #debbe4ff, white);
    }
  }
}
