.Centered {
  img {
    text-align: center;
  }
}

.VerifyEmailPage .Centered {
  width: 400px;

  .Prompt {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
