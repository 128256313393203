.Persona {
  .PersonaAvatar {
    img {
      width: 200px;
    }
  }

  .PersonaField {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
