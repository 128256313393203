body {
  background-color: #e6ebf1;
}

.BillingPage {
  padding: 5px;

  .GotoCreateBilling {
    display: block;
    margin-top: 5px;
  }
}

.BillingForm {
  padding: 10px;

  .StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .AsyncButton {
    margin-top: 10px;
  }

  .BillingOptions {
    display: flex;
    flex-wrap: wrap;
  }

  .SelectedBillingOption {
    margin-bottom: 10px;
  }

  .BillingOption {
    cursor: pointer;
    margin-right: 12px;
    margin-bottom: 12px;
    padding: 10px;
    width: 200px;
    min-width: 200px;
    background-color: white;
    border-radius: 4px;

    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;

    &.Selectable:hover {
      -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      -ms-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      -o-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    img {
      width: 200px;
      height: 200px;
    }
  }

  .BillingStep {
    .Header {
      margin-bottom: 10px;
      display: block;
    }

    .DetailsContainer {
      width: 500px;
    }

    .DetailsContainer, .SelectedBillingOption {
      vertical-align: top;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .LineItems {
    margin-bottom: 20px;
    
    .LineItemDescription, .LineItemPrice, .TotalDescription, .TotalPrice {
      display: inline-block;
    }
    .LineItemPrice, .TotalPrice {
      margin-left: 10px;
    }
    .LineItemPrice, .TotalDescription, .TotalPrice {
      font-weight: bold;
    }
    .Total {
      margin-top: 5px;
      border-top: 1px solid black;
    }
  }
}
